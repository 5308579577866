import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 사용자
        sweetdeal_user: {
            is_logined : false,
            user_id : "",
            name : "",
            birth : "",
            profile_image : "",
            marketing_kakao : "",
            marketing_sms : "",
            marketing_app : "",
            created : ""
        },

        // 관리자
        sweetdeal_admin: {
            is_logined: false,
        },

        // 계약
        contract: {
            love_start: "2024-05-01",
            my: {
                name: "",
                love_name: "",
                birth: ""
            },
            partner: {
                name: "",
                love_name: "",
                birth: ""
            },
            start_date: "2023-10-01",
            end_date: "2024-10-01",
            duty_list: [],

            sheet: {
                top: "",
                list: []
            },
        }
    },

    getters: {

    },

    mutations: {
        // 사용자 로그인
        login: (state, payload) => {
            state.sweetdeal_user.is_logined = true
            state.sweetdeal_user.user_id = payload.user_id
            state.sweetdeal_user.name = payload.name
            state.sweetdeal_user.birth = payload.birth
            state.sweetdeal_user.profile_image = payload.profile_image
            state.sweetdeal_user.marketing_kakao = payload.marketing_kakao
            state.sweetdeal_user.marketing_sms = payload.marketing_sms
            state.sweetdeal_user.marketing_app = payload.marketing_app
            state.sweetdeal_user.created = payload.created
        },

        // 사용자 로그아웃
        logout: (state) => {
            state.sweetdeal_user.is_logined = false
            state.sweetdeal_user.user_id = ""
            state.sweetdeal_user.name = ""
            state.sweetdeal_user.birth = ""
            state.sweetdeal_user.profile_image = ""
            state.sweetdeal_user.marketing_kakao = ""
            state.sweetdeal_user.marketing_sms = ""
            state.sweetdeal_user.marketing_app = ""
            state.sweetdeal_user.created = ""
        },

        // 사용자 프로필 수정하기
        update_profile_image: (state, payload) => {
            state.sweetdeal_user.profile_image = payload
        },

        // 관리자 로그인
        admin_login: (state) => {
            state.sweetdeal_admin.is_logined = true
        },

        // 관리자 로그아웃
        admin_logout: (state) => {
            state.sweetdeal_admin.is_logined = false
        },

        // 계약 > 연애시작일 입력
        contract_update_love_start: (state, payload) => {
            state.contract.love_start = payload
        },

        // 계약 > 본인 입력
        contract_update_my: (state, payload) => {
            state.contract.my = payload
        },

        // 계약 > 상대방 입력
        contract_update_partner: (state, payload) => {
            state.contract.partner = payload
        },

        // 계약 > 계약기간 입력
        contract_update_term: (state, payload) => {
            state.contract.start_date = payload.start_date
            state.contract.end_date = payload.end_date
        },

        // 계약 > 의무사항 목록 입력
        contract_update_duty_list: (state, payload) => {
            state.contract.duty_list = payload
        },

        // 계약 > 계약서 입력
        contract_update_sheet: (state, payload) => {
            state.contract.sheet = payload
        },

        resetContract(state) {
            state.contract = {
                love_start: "",
                my: {
                    name: "",
                    love_name: "",
                    birth: ""
                },
                partner: {
                    name: "",
                    love_name: "",
                    birth: ""
                },
                start_date: "2023-10-01",
                end_date: "2024-10-01",
                duty_list: [],
                sheet: {
                    top: "",
                    list: []
                },
            };
        }
    },

    actions: {

    },

    modules: {

    },

    plugins: [
        // 브라우져 꺼져도 유지
        createPersistedState({
            paths: [
                'sweetdeal_user',
                'sweetdeal_admin'
            ]
        })
    ]
})
