import GlobalSnackbar from '@/components/GlobalSnackbar.vue'

export default {
    install(Vue, options) {
        const SnackbarConstructor = Vue.extend(GlobalSnackbar)
        const instance = new SnackbarConstructor({
            vuetify: options.vuetify
        })
        instance.$mount(document.createElement('div'))
        document.body.appendChild(instance.$el)

        Vue.prototype.$globalSnackbar = {
            show: instance.show,
            close: instance.close
        }
    }
}
