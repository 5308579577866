 <template>
    <v-app v-if="snackbar">
        <v-snackbar
            v-model="snackbar"
            centered
            class="rounded-xl"
            rounded="pill"
            max-width="80vw"
            content-class="rounded-xl py-4 px-10"
            timeout="1200"
            light
        >
            <p
                class="my-0 text-subtitle-1 primary--text text-center"
            >
                {{message}}
            </p>
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'GlobalSnackbar',

    data: () => ({
        snackbar: false,
        message: ""
    }),

    methods: {
        show(message) {
            this.message = message
            this.snackbar = true
        },

        close() {
            this.snackbar = false
        }
    }
}
</script>
