<template>
    <v-app style="margin:0 !important;">
        <router-view :key="$route.fullPath"></router-view>

        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile && !$route.path.startsWith('/ios') && !$route.path.startsWith('/admin')"
            class="mx-auto text-center d-flex justify-space-between mt-2"
            style="position:absolute; top:200px; left:100px; width:520px;"
        >
            <div
                style="margin-top:22px;"
            >
                <div
                    class="d-flex justify-center"
                    style="width:520px;"
                >
                    <v-icon style="margin-bottom:260px;" size="36" color="grey">mdi-format-quote-open-outline</v-icon>
                    <div>
                        <p
                            class="text-h4 font-weight-bold primary--text mb-6 mt-8"
                        >
                            우리는 커플이다
                        </p>

                        <p
                            class="text-h6 font-weight-medium grey--text text--darken-2 mb-10"
                            style="line-height:28px;"
                        >
                            우리 연인이 되었어요! 스윗딜<br/>
                            연인간의 소중한 약속, 이제 계약으로 지키세요.<br/>
                            1분만에 만드는 연인 계약 앱<br/>
                            "스윗딜"
                        </p>

                        <p
                            class="text-h5 font-weight-bold primary--text mb-2"
                        >
                            커플만의 혜택만을 확인하기
                        </p>
                        <v-icon size="24" class="mb-4" color="primary">mdi-chevron-double-down</v-icon>
                    </div>
                    <v-icon style="margin-top:80px;" size="36" color="grey">mdi-format-quote-close-outline</v-icon>
                </div>

                <div
                    class="d-flex justify-center"
                    style="width:520px;"
                >
                    <v-btn
                        color="black"
                        width="220"
                        depressed
                        large
                        class="rounded-15 d-flex py-8 mr-3"
                        @click="link()"
                    >
                        <div
                            style="width:44px;"
                        >
                            <v-img
                                class="mr-3 mb-2px"
                                src="@/assets/landing/apple_white.svg"
                            ></v-img>
                        </div>
                        <p
                            class="mb-0 text-left mr-2 white--text"
                        >
                            App Store에서<br/>
                            <strong>다운받기</strong>
                        </p>
                    </v-btn>

                    <v-btn
                        color="primary"
                        width="220"
                        depressed
                        large
                        class="rounded-15 d-flex py-8 ml-3"
                        @click="linkAndroid()"
                    >
                        <div
                            style="width:48px;"
                        >
                            <v-img
                                class="mr-3"
                                src="@/assets/landing/google.png"
                            ></v-img>
                        </div>
                        <p
                            class="mb-0 text-left mr-1"
                        >
                            Google Play에서<br/>
                            <strong>다운받기</strong>
                        </p>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
export default {
    name: 'App',

    methods: {
        link(){
            window.open('https://apps.apple.com/us/app/%EC%8A%A4%EC%9C%97%EB%94%9C-%EC%9A%B0%EB%A6%AC-%EA%B3%84%EC%95%BD%ED%96%88%EC%96%B4%EC%9A%94/id6464309080', '_self')
        },

        linkAndroid(){
            alert("준비중입니다.")
            // window.open('https://play.google.com/store/apps/details?id=com.sweetdeal.sweetdeal_new&hl=ko', '_self')
        }
    }
}
</script>
