export const routes = [
    {
        path: '/tests',
        component: () => import(/* webpackChunkName: "Common" */ '@/tests/index.vue')
    },
    {
        path: '/ios',
        component: () => import(/* webpackChunkName: "Common" */ '@/tests/ios.vue')
    },
    {
        path: '/ci_web',
        component: () => import(/* webpackChunkName: "Common" */ '@/views/ci_web.vue')
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/home.vue')
            },
            {
                path: 'read',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/read.vue')
            },
            {
                path: 'list',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/list.vue')
            },
            {
                path: 'contract',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/contract.vue')
            },

            // 위시리스트
            {
                path: 'wishlist',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/wishlist.vue')
            },
            {
                path: 'wishlist_list',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/wishlist_list.vue')
            },

            // # 마지노선
            {
                path: 'maginotline',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/maginotline.vue')
            },
            {
                path: 'maginotline_list',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/maginotline_list.vue')
            },

            // AI
            {
                path: 'ai',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'face',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/face.vue')
                    },
                    {
                        path: 'face_match',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/face_match.vue')
                    },
                    {
                        path: 'face_matching',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/face_matching.vue')
                    },
                    {
                        path: 'quote',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/quote.vue')
                    },
                    {
                        path: 'cheer',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/cheer.vue')
                    },
                    {
                        path: 'book',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/book.vue')
                    },
                    {
                        path: 'match',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/match.vue')
                    },
                    {
                        path: 'today_fortune',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/ai/fortune.vue')
                    },
                    {
                        path: 'star',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/star.vue')
                    },
                    {
                        path: 'counseling',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/ai/counseling.vue')
                    },
                ]
            },

            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'join',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/auth/join.vue')
                    },
                    {
                        path: 'find_password',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find_password.vue')
                    },
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/logout.vue')
                    },
                ]
            },

            // 마이페이지
            {
                path: 'mypage',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'dashboard',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage/dashboard.vue')
                    },
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage/list.vue')
                    },
                    {
                        path: 'wishlist',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage/wishlist.vue')
                    },
                    {
                        path: 'maginotline',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage/maginotline.vue')
                    },
                    {
                        path: 'cs',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage/cs.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage/update.vue')
                    },
                ]
            },

            // 정책 페이지
            {
                path: 'policy',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'rule',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/policy/rule.vue')
                    },
                    {
                        path: 'privacy',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/policy/privacy.vue')
                    },
                    {
                        path: 'marketing',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/policy/marketing.vue')
                    }
                ]
            },
        ]
    },

    // 관리자 페이지
    {
        path: '/admin',
        meta: { sitemap: { ignoreRoute: true } },
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/logout.vue')
                    },
                ]
            },

            // 이벤트 관리
            {
                path: 'event',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/event/list.vue')
                    },
                    {
                        path: 'create',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/event/create.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/event/update.vue')
                    },
                ]
            },

            // 키워드 관리
            {
                path: 'keyword',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/keyword.vue'),
            },

            // 계약 관리
            {
                path: 'contract',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/contract.vue'),
            },

            // 위시리스트 관리
            {
                path: 'wishlist',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/wishlist.vue'),
            },

            // 마지노선 관리
            {
                path: 'maginotline',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/maginotline.vue'),
            },

            // 회원 관리
            {
                path: 'user',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/user.vue'),
            },

            // AI 관리
            {
                path: 'ai',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/ai.vue'),
            },

            // 관리자 정보수정
            {
                path: 'update',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/update.vue'),
            }
        ]
    }
]